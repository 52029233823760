<template>
    <section>
        <Spinner v-if="isLoading" />
        <pre v-else class="text-sm">{{ currentBOM }}</pre>
    </section>
</template>
<script setup lang="ts">
const route = useRoute();
const { fetchBOM } = useBOM();

const currentBOM = ref<BOMSearch>();
const isLoading = ref(true);

onMounted(async () => {
    try {
        isLoading.value = true;
        currentBOM.value = await fetchBOM(route.params.hash);
    } catch (err) {
        showError(err as Error);
    } finally {
        isLoading.value = false;
    }
});

provide(BOMInjectKey, { currentBOM });
</script>
